import { Link } from "react-router-dom";

const Work = () => {
    const works = [
        {
            'job':'Fullstack developer',
            'company':'CruCon Cruise Outlet',
            'duration':'November 2022 - Present',
            'bullets':[
                "Worked with other developers to plan, create, and ship internal and external features",
                "Worked with non-technical employees to create technical tools that increased their productivity",
                "Worked with large datasets to manipulate and find best prices on sailings from multiple different vendors"
            ]
        }
    ]

    const projects = [
        {
            'name':'Portfolio website',
            'desc':'website showcasing projects, and information about myself',
            'tech': 'React - Tailwind CSS',
            'link':'https://github.com/benjaminavachon/portfolio'
        },
        {
            'name':'Media Player',
            'desc':'School project done in a group, creating a media player using Java that you can use to import music from your computer, and created nested playlists',
            'tech': 'Java',
            'link':'https://github.com/benjaminavachon/CS4140-Media-Player'
        },
        {
            'name':'Simple Forum website',
            'desc':'School project to create a reddit-like forum website that allows users to post content, and reply on posts.',
            'tech': 'PHP - JavaScript - HTML - CSS - SQL',
            'link':'https://github.com/benjaminavachon/CS3020-Final'
        },
        {
            'name':'Simple C Shell',
            'desc':'School project, creating a simple unix shell',
            'tech': 'C',
            'link':'https://github.com/benjaminavachon/c-shell'
        },
    ];

    return(
        <div className="text-main min-h-screen bg-[#204B60]">
            <div className="min-h-screen bg-white lg:w-1/2 m-auto p-5">
                {/* This is where my work experience goes */}
                <h1 className="text-center text-4xl underline">Work Experience</h1>
                <div className="text-main text-xl text-center m-auto mb-5">
                    Below is my professional work experience in the tech field
                </div>
                {
                    works.map((work) => {
                        return(
                            <div className="lg:w-5/6 m-auto border border-main rounded-md p-2 mb-5">
                                <div className="text-center text-xl font-semibold underline pt-5">
                                    {work.job}
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="text-left">
                                        {work.company}
                                    </div>
                                    <div className="text-right">
                                        {work.duration}
                                    </div>
                                </div>
                                <div>
                                    <ul className="list-disc px-5 border-t">
                                        {
                                            work.bullets.map((bullet) => {
                                                return(
                                                    <li>
                                                        {bullet}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }

                {/* This is where my github projects go */}
                <h1 className="text-center text-4xl underline">Projects</h1>
                <div className="text-main text-xl text-center mb-5">
                    Below are projects I have completed in school or on my own time. card links to its appropriate GitHub repo.
                </div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 lg:w-5/6 m-auto mb-5">
                    {
                        projects.map((item) => {
                            return(
                                <Link to={item.link} target="_blank">           
                                    <div className="bg-background text-main text-center rounded-t-md">
                                        {item.name}
                                    </div>
                                    <div className="border rounded-b-md">
                                        <div className="border-b px-2">
                                            {item.desc}
                                        </div>
                                        <div className="px-2">
                                            {item.tech}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Work