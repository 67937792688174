import React from "react";
import { NavLink } from "react-router-dom";

export default function Navbar({ fixed }) {
	const tabs = [
		{ 'name':'Home', 'path':'/' },
		{ 'name':'Work', 'path':'/Work' },
		{ 'name':'Resume', 'path':'/Resume' },
		{ 'name':'Baseball', 'path':'/Baseball' },
	];

	const [navbarOpen, setNavbarOpen] = React.useState(false);
	return (
	<>
	  <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-background">
		<div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
		  <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
			<NavLink
			  className="text-sm font-bold leading-relaxed flex mr-auto mt-auto mb-auto py-2 whitespace-nowrap uppercase text-main"
			  to="/"
			>
			  Benjamin Vachon
			</NavLink>
			<button
			  className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
			  type="button"
			  onClick={() => setNavbarOpen(!navbarOpen)}
			>
			  <svg className="fill-white" viewBox="0 0 100 80" width="40" height="40">
								<rect width="100" height="10"></rect>
								<rect y="30" width="100" height="10"></rect>
								<rect y="60" width="100" height="10"></rect>
							</svg>
			</button>
		  </div>
		  <div
			className={
			  "lg:flex flex-grow items-center" +
			  (navbarOpen ? " flex" : " hidden")
			}
			id="example-navbar-danger"
		  >
			<ul className="flex flex-col lg:flex-row list-none lg:ml-auto gap-4">
				{
					tabs.map((tab) => (
							<li className="nav-item">
								<NavLink 
									to={tab.path} 
									className={({ isActive }) => {
										return (isActive ? 'text-main underline font-semibold' : 'text-white')
									}}
								>
									{tab.name}
								</NavLink>
							</li>
					))
				}
			</ul>
		  </div>
		</div>
	  </nav>
	</>
  );
}
