import logo from '../Images/cert1.jpg'
import logo1 from '../Images/cert2.jpg'
import { 
    DiCss3,
    DiGithubBadge,
    DiHtml5,
    DiJsBadge,
    DiLinux,
    DiMysql,
    DiNodejs,
    DiPhp,
    DiRust,
    DiReact,
    DiPython,
    DiWindows,
    DiMongodb,
    DiJava,
} from "react-icons/di";

import { FaBootstrap } from "react-icons/fa";

import { SiC, SiCplusplus, SiMacos, SiR, SiGo } from "react-icons/si";

const Home = () => {
    return (
        <div>
            {/* About */}
            <div className="bg-background">
                <h1 className="text-main text-4xl underline text-center font-semibold py-3">About</h1>
                <div className="lg:w-1/2 text-center m-auto text-main text-xl pb-8">
                    My name is Benjamin Vachon, I graduated in 2022 with a BS in Computer Science, with double minors in Mathematics
                    and Cybersecurity. I am highly motivated and have professional interests in baseball analytics
                    and low-level/embedded programming.
                </div>
            </div>

            {/* Certifications */}
            <div className="py-5">
                <h1 className="text-main text-4xl underline text-center font-semibold py-3">Certifications</h1>
                <div className="grid lg:grid-cols-2 content-start lg:w-1/2 m-auto gap-4">
                    <div className="border rounded-md">
                        <div className="text-main bg-background text-center rounded-t-md text-3xl">
                            SABR Analytics Level 1
                        </div>
                        <img src={ logo } alt="SABR CERT 1" />
                    </div>

                    <div className="border rounded-md">
                        <div className="text-main bg-background text-center rounded-t-md text-3xl">
                            SABR Analytics Level 2
                        </div>
                        <img src={ logo1 } alt="SABR CERT 2" />
                    </div>
                </div>
            </div>

            {/* Skills */}
            <div className="bg-background py-5">
                <h1 className="text-main text-4xl underline text-center font-semibold pb-8">Skills</h1>
                <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 content-start lg:w-2/3 m-auto gap-5">
                    {/* First Row */}
                    <div>
                        <DiPython className="m-auto text-6xl text-main" />
                    </div>
                    <div>
                        <SiC className="m-auto text-6xl text-main" />
                    </div>
                    <div>
                        <SiCplusplus className="m-auto text-6xl text-main" />
                    </div>
                    <div>
                        <DiMysql className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiMongodb className="m-auto text-7xl text-main" />
                    </div>
                    {/* Second Row */}
                    <div>
                        <SiR className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiJsBadge className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiJava className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <SiGo className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiRust className="m-auto text-7xl text-main"/>
                    </div>
                    {/* Third Row */}
                    <div>
                        <DiHtml5 className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiCss3 className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiPhp className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <FaBootstrap className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiReact className="m-auto text-7xl text-main" />
                    </div>
                    
                    
                    {/* Fourth Row */}
                    <div>
                        <DiNodejs className="m-auto text-7xl text-main" />
                    </div>
                    <div>
                        <DiGithubBadge className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiLinux className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <DiWindows className="m-auto text-7xl text-main"/>
                    </div>
                    <div>
                        <SiMacos className="m-auto text-7xl text-main"/>
                    </div>
                    
                    
                </div>
            </div>

            {/* interests */}
            <div>
                <h1 className="text-main text-4xl underline text-center font-semibold py-3">Interests</h1>
                <div className="lg:w-1/2 text-center m-auto text-main text-xl pb-8">
                    In my free time I like to watch and read about baseball. I also started to get into college
                    baseball more and more as I attended college, and I find myself watching mostly SEC games
                    I also spend time reading and watching documentaries mostly about history. I especially enjoy watching documentaries on early
                    computers.
                </div>
            </div>
            
        </div>
    );
}

export default Home