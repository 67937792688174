import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Resume = () => {

    const doc = [{ uri: require("../Files/resume.pdf") }]

    return(
        <div className="text-main">
            <DocViewer documents={doc} pluginRenderers={DocViewerRenderers} className="min-h-full"/>
        </div>
    )
}

export default Resume