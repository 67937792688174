import { useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Baseball = () => {
	const [doc, setDoc] = useState([{ uri: require("../Files/pitcher_comparison.pdf") }]);
	function onSelect(event){
		const selectedIndex = event.target.options.selectedIndex;
		setDoc(docs[selectedIndex].doc);
	}

	const docs = [
		{
			'name': 'Pitcher Comparison',
			'doc': [{ uri: require("../Files/pitcher_comparison.pdf") }]
		},
		{
			'name':'Pitcher Comparison (Excel)',
			'doc': [{ uri: require("../Files/pitcher_comparison_excel.pdf") }]
		},
		{
			'name':'Position Player Comparison',
			'doc': [{ uri: require("../Files/position_player.pdf") }]
		},
		{
			'name':'Position Player Comparison (Excel)',
			'doc': [{ uri: require("../Files/position_player_excel.pdf") }]
		},
		{
			'name': 'Juan Soto Contract Essay',
			'doc': [{ uri: require("../Files/Juan _Soto_Contract.pdf") }]
		},
	]
	

	return (
		<div className="grid grid-cols-6">
			<div className="text-main text-center min-h-screen">
				<select size={docs.length} onChange={onSelect} className="overflow-auto w-full">
					{
						docs.map((doc) => {
							return (
								<option key={doc.doc} className="text-xl py-3 border-b">
									{doc.name}
								</option>
							)
						})
					}
				</select>
			</div>
			<div className="col-span-5">
				<DocViewer documents={doc} pluginRenderers={DocViewerRenderers} className="min-h-full"/>
			</div>
		</div>
	)
}

export default Baseball