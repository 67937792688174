import { DiGithubBadge } from "react-icons/di"
import { SiLinkedin } from "react-icons/si"
import { HiMail } from "react-icons/hi";
import { NavLink } from "react-router-dom"

const Footer = () => {
    return(
        <div className="bg-background">
            <div className="w-5/6 m-auto grid lg:grid-cols-4 sm:grid-cols-2 py-5">
                <div className="bg-inherit text-main text-4xl col-span-3 flex my-auto">
                Benjamin Vachon
            </div>
            <div className="bg-inherit grid grid-cols-3">
                <div>
                    <NavLink to="https://github.com/benjaminavachon" target="_blank">
                        <DiGithubBadge className="text-main text-6xl ml-auto" />
                    </NavLink>
                </div>
                <div>
                    <NavLink to="https://www.linkedin.com/in/benjamin-a-vachon/" target="_blank">
                        <SiLinkedin className="text-main text-5xl ml-auto flex my-2" />
                    </NavLink>
                </div>
                <div>
                    <NavLink onClick={() => window.location = 'mailto:benjaminavachon@gmail.com'} target="_blank">
                        <HiMail className="text-main text-6xl ml-auto" />
                    </NavLink>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Footer