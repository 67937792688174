import { BrowserRouter, Routes, Route } from "react-router-dom";

//import pages
import Home from './Pages/Home'
import Baseball from './Pages/Baseball';
import Work from './Pages/Work';
import Resume from './Pages/Resume';

//import components
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes className="pages">
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/Work"
            element={<Work />}
          />
          <Route
            path="/Resume"
            element={<Resume />}
          />
          <Route
            path="/Baseball"
            element={<Baseball />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
